import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty, last as lastMethod } from 'lodash'

import withForm from '../../../hoc/withForm'
import withPaymentControl from '../../../hoc/withPaymentControl'
import { useSelector } from 'react-redux'
import CircleQuestionIcon from '@ui-components-3/icons/lib/duotone/CircleQuestion'
import { getPaymentMethods } from '../../../redux/selectors/payments'
import * as APM from 'util/apm'
import TestIds from '../../../util/TestIds'
import PaymentGraphicUrl from '../../../assets/images/payment_form_graphic.svg'
import { cardExpiration, logoForCard, obfuscatedCardNumber } from '../../../util/payments'
import Config from '../../../config'
import { getMember } from '../../../redux/selectors/members'
import { getPreferredAddress } from '../../../util/member'
import VisitPrice from '../../../components/visit-creation/VisitPrice'
import AngleLeftIcon from '@ui-components-3/icons/lib/regular/AngleLeft'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import PlusIcon from '@ui-components-3/icons/lib/duotone/CirclePlus'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import AddPaymentMethodDialog from '../../../components/account/AddPaymentMethodDialog'
import { HELP_CENTER } from '../../../routes/paths'
import { Link, useLocation } from 'react-router-dom'
import { APP_NAME } from 'util/constants'
import PendingVisit from 'types/visit/pendingVisit'
import { AnyObjectSchema } from 'yup'
import type { Dispatch } from 'redux'
import { CardResponse } from 'types/account/payment'
import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import PaymentInfoDialog from '../PaymentInfoDialog'
import { isSuccess } from 'util/helpers'

const RADIO_CARD_WIDTH = 270

type PaymentFormProps = {
  memberId: string
  pendingVisit: PendingVisit
  initialValues: { paymentTransactionId: string }
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  setErrors: () => void
  values: CardResponse
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  onBack: () => void
  submitForm: () => void
  onCancel: () => void
  addTransaction: (transaction: any) => Promise<
    (dispatch: Dispatch) => Promise<{
      success: boolean
      data: string | null
    }>
  >
  addPaymentMethod: (source: any) => Promise<
    (dispatch: Dispatch) => Promise<{
      success: boolean
      data: any
    }>
  >
  addPaymentClient: () => Promise<(dispatch: Dispatch, getState: () => any) => Promise<boolean>>
  fetchPaymentMethods: () => null | { success: boolean; data: Record<string, any>[] }
  addErrorRole?: () => void
  removeErrorRole?: () => void
  isOverLimit?: boolean
}

export const PaymentForm = (props: PaymentFormProps) => {
  const infoDialogRef = useRef<ModalRef>(null)

  const {
    memberId,
    values,
    addPaymentMethod,
    addTransaction,
    handleSubmit,
    pendingVisit,
    setFieldValue,
    submitForm,
    fetchPaymentMethods,
    addPaymentClient,
    addErrorRole,
    removeErrorRole,
    onBack,
    isOverLimit,
  } = props

  const methods = useSelector(getPaymentMethods)
  const member = useSelector(getMember(memberId))

  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)
  const [isSelectingPayment, setIsSelectingPayment] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [error, setError] = useState<string | null>(null)
  const cancelDialogRef = useRef<ModalRef>()
  const handleOpenSelectPayment = useCallback(() => setIsSelectingPayment(true), [])

  const paymentModalRef = useRef<ModalRef>(null)

  const location = useLocation<{ isOverLimit?: boolean }>()

  const successVisit = useMemo(() => isSuccess(pendingVisit.visitType), [pendingVisit.visitType])
  const overLimit = useMemo(
    () => (successVisit ? isOverLimit : location.state?.isOverLimit),
    [isOverLimit, location, successVisit],
  )
  const sessionOrVisit = useMemo(() => (successVisit ? 'session' : 'visit'), [successVisit])

  const handlePaymentMethods = useCallback(async () => {
    const methodsResult = await fetchPaymentMethods()
    if (methodsResult) {
      const { success, data } = methodsResult
      if (success) {
        if (!data || data.length === 0) {
          addPaymentClient()
        }
      } else {
        console.error('error adding payment client')
      }
    } else {
      console.error('No member id')
    }
  }, [memberId, fetchPaymentMethods])

  useEffect(() => {
    if (!isEmpty(methods)) {
      const last = lastMethod(methods)
      setSelectedMethod(last.id)
    }
  }, [methods])

  useEffect(() => {
    handlePaymentMethods()
  }, [memberId])

  useEffect(() => {
    if (!isEmpty(errorMessages)) console.error(errorMessages)
  }, [errorMessages])

  useEffect(() => {
    infoDialogRef.current?.open()
  }, [])

  const price = useMemo(() => {
    if (!pendingVisit) return ''
    return (!!pendingVisit.price && `${Number(pendingVisit.price).toFixed(2)}`) || '0.00'
  }, [pendingVisit])

  const cardNonceGoogleResponseReceived = useCallback(
    async (method: CardResponse) => {
      if (method.status === 'OK') {
        setErrorMessages([])
        const { token, details } = method
        if (isEmpty(details.method) || details.method === 'Card') {
          // @ts-ignore
          const { success } = await addPaymentMethod(token)
          if (success) {
            paymentModalRef.current?.close()
          } else {
            setError('Unable to add this card')
          }
        } else {
          const transactionResult = await addTransaction({
            id: token,
            // TODO: This needs to be removed in the future once the backend calculates overLimit
            isOverLimit: overLimit,
          })
          // @ts-ignore
          if (transactionResult.success) {
            // @ts-ignore
            setFieldValue('paymentTransactionId', transactionResult.data)
            submitForm()
          } else {
            console.error('error adding transaction with digital wallet')
          }
        }
      } else {
        console.error('error retrieving your payment method from your digital wallet')
      }
    },
    [addPaymentMethod, setErrorMessages, setFieldValue, submitForm, addTransaction, overLimit],
  )

  const cardNonceResponseReceived = useCallback(
    async (method: CardResponse) => {
      const { token } = method
      if (isEmpty(methods)) {
        try {
          console.log('adding a payment client')
          await addPaymentClient()
        } catch (e: any) {
          console.error(e)
          APM.captureException(e)
        }
      }
      // @ts-ignore
      const { success } = await addPaymentMethod(token)

      if (!success) {
        setError('Unable to add this card')
      } else {
        paymentModalRef.current?.close()
      }
    },
    [addPaymentMethod, addPaymentClient, methods],
  )

  const createPaymentRequest = useCallback(() => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: 'US',
      total: {
        label: APP_NAME,
        amount: price,
        pending: false,
      },
    }
  }, [price])

  const postalCode = useCallback(() => {
    const billingAddress = getPreferredAddress(member, 'billing')
    if (!billingAddress) return ''
    return billingAddress.zip
  }, [member])

  const handleMethodClick = useCallback((methodId: string) => {
    if (methodId === selectedMethod) {
      setSelectedMethod(null)
    } else {
      setSelectedMethod(methodId)
    }
  }, [])

  const handleNextClick = useCallback(async () => {
    const method = methods.find((method) => method.id === selectedMethod)
    if (!method) return
    // @ts-ignore
    const { success, data } = await addTransaction({
      ...method,
      // TODO: This needs to be removed in the future once the backend calculates overLimit
      isOverLimit: overLimit,
    })

    if (success) {
      setFieldValue('paymentTransactionId', data)
      setTimeout(submitForm)
    } else {
      console.error('error adding transaction')
    }
  }, [methods, selectedMethod, pendingVisit, values, overLimit])

  const paymentFormProps = useMemo(() => {
    return {
      sandbox: !Config.isProduction,
      applicationId: Config.squareAppId,
      locationId: Config.squareLocationId,
      cardTokenizeResponseReceived: cardNonceResponseReceived,
      createPaymentRequest,
      postalCode,
    }
  }, [cardNonceResponseReceived, postalCode, createPaymentRequest])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googlePaymentFormProps = useMemo(() => {
    return {
      sandbox: !Config.isProduction,
      applicationId: Config.squareAppId,
      locationId: Config.squareLocationId,
      cardTokenizeResponseReceived: cardNonceGoogleResponseReceived,
      createPaymentRequest,
      postalCode,
    }
  }, [cardNonceGoogleResponseReceived, postalCode, createPaymentRequest])

  const radioGroupLabelId = useId()

  const renderPaymentForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit} data-testid={TestIds.newVisit.view.paymentForm}>
          <div className="pb-6">
            {!!methods?.length && (
              <>
                <div className="typography-body-xl mb-4 font-semibold text-neutral-500" id={radioGroupLabelId}>
                  Select a card:
                </div>
                <RadioCardsGroup
                  aria-labelledby={radioGroupLabelId}
                  columnWidth={RADIO_CARD_WIDTH}
                  options={methods}
                  name="payment-card"
                  value={selectedMethod ?? undefined}
                  onChange={(e) => handleMethodClick(e.target.value)}
                  getOptionValue={(method) => method.id}
                  getOptionLabel={(method) => (
                    <div data-testid={TestIds.newVisit.label.card(methods.indexOf(method))}>
                      <div className="typography-body mb-1 font-semibold text-neutral-800">
                        {obfuscatedCardNumber(method)}
                      </div>
                      <div className="typography-body-l text-primary-600 mb-3 font-medium">
                        {cardExpiration(method)}
                      </div>
                      <div>{logoForCard(method)}</div>
                    </div>
                  )}
                />
                <hr className="my-m w-full border-b border-dashed border-b-neutral-300" />
              </>
            )}
            <button
              data-testid={TestIds.newVisit.button.addCard}
              onClick={() => paymentModalRef.current?.open()}
              className={classNames('btn-small', isEmpty(methods) ? 'btn-primary' : 'btn-primary-outlined')}
              type="button"
            >
              Add Card
              <PlusIcon
                aria-hidden="true"
                className={classNames('h-[24px] w-[24px]', isEmpty(methods) ? 'text-white' : 'text-primary-800')}
              />
            </button>
            <AddPaymentMethodDialog
              ref={paymentModalRef}
              paymentFormProps={paymentFormProps}
              addErrorRole={addErrorRole}
              removeErrorRole={removeErrorRole}
            />
          </div>
        </form>
      </>
    )
  }

  const renderPaymentScene = () => {
    if (overLimit) {
      return (
        <>
          <div className="flex gap-5" data-testId={TestIds.newVisit.view.paymentPayWall}>
            <div className="text-neutral flex max-w-[400px] flex-col gap-4">
              <h2 className="typography-h4">Submit Payment</h2>
              <p className="typography-body leading-5 text-neutral-600">
                You&apos;ve reached the number of free {sessionOrVisit}s provided by your school, but our providers are
                still here for you. Just purchase a new {sessionOrVisit} below.
              </p>
              <p className="typography-body-xl font-medium">The cost for this {sessionOrVisit} is:</p>
              <h3 className="typography-h3">${price}</h3>
            </div>
            <div className="hidden lg:block">
              <img src={PaymentGraphicUrl} alt="" />
            </div>
          </div>
          {!isSelectingPayment && (
            <button onClick={handleOpenSelectPayment} className="btn btn-primary mt-10 self-start">
              Select Payment Method
              <AngleRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
          <Link to={HELP_CENTER} className="my-7 max-w-[400px]">
            <div className="bg-secondary-200 rounded-md p-4">
              <p className="typography-body-l font-semibold text-neutral-600">
                Need help with a {sessionOrVisit}? Contact support:
              </p>
              <div className="mt-1 flex items-center gap-2">
                <CircleQuestionIcon className="h-5 w-5 text-neutral-600" aria-hidden="true" />
                <p className="typography-body-l font-semibold text-neutral-600">Help Center</p>
              </div>
            </div>
          </Link>
          {!!isSelectingPayment && renderPaymentForm()}
        </>
      )
    } else {
      return (
        <>
          <div
            className="mb-8 border-b border-dashed border-b-neutral-300 pb-4"
            data-testId={TestIds.newVisit.view.paymentView}
          >
            <VisitPrice price={price} successVisit={successVisit} />
          </div>
          {renderPaymentForm()}
        </>
      )
    }
  }

  return (
    <div className="flex w-full flex-1 flex-col">
      {renderPaymentScene()}
      <div className="fixed bottom-0 left-5 right-5 z-10 -mx-5 mt-auto flex min-h-[100px] flex-col justify-between border-t border-t-neutral-300 bg-white px-5 py-4 md:flex-row md:items-center lg:left-[290px] lg:right-[40px] lg:-mx-10">
        <button
          data-testid={TestIds.newVisit.button.cancel}
          className="btn btn-neutral-outlined mb-3 min-w-[120px] shrink-0 md:mb-0 lg:min-w-[180px]"
          onClick={() => {
            cancelDialogRef.current?.open()
          }}
          aria-label={'Cancel Visit'}
          type="button"
        >
          Cancel
        </button>
        <div className="flex items-center justify-between">
          <button
            className="btn btn-primary-outlined mb-3 mr-2 min-w-[120px] flex-1 md:mb-0 md:mr-8 lg:min-w-[180px]"
            data-testid={TestIds.newVisit.button.back}
            onClick={onBack}
            type="button"
          >
            <AngleLeftIcon className="h-6 w-6" aria-hidden="true" />
            Back
          </button>
          <button
            className="btn btn-primary mb-3 min-w-[120px] flex-1 md:mb-0 lg:min-w-[180px]"
            onClick={handleNextClick}
            disabled={overLimit ? !selectedMethod || !isSelectingPayment : !selectedMethod}
            data-testid={TestIds.newVisit.button.next}
            type="button"
          >
            Next
            <AngleRightIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <PaymentInfoDialog ref={infoDialogRef} sessionOrVisit={sessionOrVisit} />
      {!!error && (
        <Modal label="Error" opened onClose={() => setError(null)}>
          <p>{error}</p>
        </Modal>
      )}

      {!!props.onCancel && (
        <Modal
          ref={cancelDialogRef}
          label="Cancel Coaching Session"
          footer={({ close }) => (
            <div className="gap-s flex w-full">
              <button type="button" className="btn btn-neutral-outlined flex-1" onClick={close}>
                No
              </button>
              <button type="button" className="btn btn-fuchsia-outlined flex-1" onClick={props.onCancel}>
                Cancel Session
              </button>
            </div>
          )}
        >
          <p className="typography-body-l my-xs">Are you sure you want to cancel Coaching Session?</p>
        </Modal>
      )}
    </div>
  )
}

/* TODO: remove any */
export default withForm(withPaymentControl(PaymentForm) as any)
