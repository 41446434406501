import FetchType from '../../util/fetch-types'
import * as ProviderSelectors from '../selectors/providers'
import { handleRequestFailure, makeRequest } from '../../api/helpers'
import { determineRequestNeediness } from './api'
import { setFetching } from '../slices/api'
import { putProvider, putProviderFetching, putProviders } from '../slices/providers'
import { putMember, putMemberProviders } from '../slices/members'
import { fetchMe } from './members'
import VisitScheduleType from '../../util/visit-schedule-types'
import { putAvailability } from '../slices/scheduling'
import VisitTypes from '../../util/visit-types'
import { getServiceRestrictions } from '../selectors/services'
import RestrictionTypes from '../../util/restriction-types'
import { isRecommendedProvider, sortProvidersByAvailability } from '../../util/provider'
import { RECOMMENDED_PROVIDER_COUNT } from '../../util/constants'
import { isUSLocation } from 'util/visit-location'

const transformMemberProvider = (provider) => {
  if (!provider) return null
  const phones = [
    {
      number: provider.phone,
      type: 'office',
    },
  ]

  const result = {
    phones,
    name: `${provider.firstName} ${provider.lastName}`,
    providerType: provider.providerType,
    specialty: provider.specialty,
    suffix: provider.suffix,
    shareRecords: false,
  }
  if (provider.email) result.email = provider.email
  if (provider.fax) {
    result.phones.push({
      number: provider.fax,
      type: 'fax',
    })
  }
  return result
}

export const addProvider = (memberId, provider) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.addProvider,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId, provider: transformMemberProvider(provider) }
  const handleSuccess = async (data) => {
    dispatch(putMember({ member: data }))
    result = data
    success = true
  }
  await makeRequest(FetchType.addProvider, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.addProvider,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const getMemberProviders = (memberId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.getMemberProviders,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId }
  const handleSuccess = async (data) => {
    dispatch(putMemberProviders(data))
    result = data
    success = true
  }
  await makeRequest(FetchType.getMemberProviders, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.getMemberProviders,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const updateProvider = (memberId, providerId, provider) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.updateProvider,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId, providerId, provider: transformMemberProvider(provider) }
  const handleSuccess = async (data) => {
    dispatch(putMember({ member: data }))
    result = data
    success = true
  }
  await makeRequest(FetchType.updateProvider, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.updateProvider,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const removeProvider = (memberId, providerId) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.removeProvider,
      isFetching: true,
    }),
  )
  let success = false
  let result = null
  const params = { memberId, providerId }
  const handleSuccess = async (data) => {
    await dispatch(fetchMe())
    result = data
    success = true
  }
  await makeRequest(FetchType.removeProvider, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(
    setFetching({
      fetchType: FetchType.removeProvider,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const fetchProviders = (page, limit, sort, filter) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getProviders, isFetching: true }))
  const params = { params: { page, limit, sort, filter } }
  const handleSuccess = () => {
    // dispatch(putPaginatedProviders(pageKey, data.paginated, data.meta.results));
    // dispatch(putLastPageFetch(pageKey, new Date()));
  }
  await makeRequest(FetchType.getProviders, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getProviders, isFetching: false }))
}

export const fetchProvidersIfNeeded =
  (page = 0, limit = 1, sort = { lastName: 1 }, filter = {}) =>
  async (dispatch) => {
    dispatch(setFetching({ fetchType: FetchType.maybeGetProviders, isFetching: true }))
    dispatch(
      determineRequestNeediness({
        cacheTtlInMinutes: 5,
        fetchNext: fetchProviders(page, limit, sort, filter),
        // lastFetchSelector: ProviderSelectors.getProvidersLastPageFetch(page, limit, sort, filter)
      }),
    )
    dispatch(setFetching({ fetchType: FetchType.maybeGetProviders, isFetching: false }))
  }

export const fetchAllProviders = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getAllProviders, isFetching: true }))
  let limit = 50
  const limitParams = { params: { page: 0, limit: 1, sort: { lastName: 1 }, filter: {} } }
  const handleLimitSuccess = (data) => {
    limit = data.meta.results
  }
  await makeRequest(FetchType.getProviders, limitParams, handleLimitSuccess, handleRequestFailure(dispatch))

  const params = { params: { page: 0, limit, sort: { lastName: 1 }, filter: {} } }
  const handleSuccess = (data) => {
    dispatch(putProviders({ providers: data.paginated }))
  }
  await makeRequest(FetchType.getProviders, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(setFetching({ fetchType: FetchType.getAllProviders, isFetching: false }))
}

const randomInt = (max = 500) => Math.floor(Math.random() * max)

export const fetchAvailableProviders =
  (memberId, visitType, scheduledType, location, filters, locationCountry, sendLicenseActiveQuery) =>
  async (dispatch) => {
    dispatch(
      setFetching({
        fetchType: FetchType.getAvailableProviders,
        isFetching: true,
      }),
    )

    const selectedLocation = isUSLocation(locationCountry) ? location : locationCountry

    let success = false
    let result = null

    const params = {
      location: selectedLocation,
      visitType,
      params: {
        filter: {
          memberId,
          ...filters,
        },
      },
    }

    if (sendLicenseActiveQuery && scheduledType === VisitScheduleType.scheduled) {
      params.params.filter['licenses.active'] = 1
    }

    const mapAvailableProvider = (provider) => {
      return {
        ...provider,
        schedulable: { visitType, location, order: randomInt() },
      }
    }

    const handleSuccess = (data) => {
      success = true
      result = data
      if (Array.isArray(data)) {
        const results = data.sort(sortProvidersByAvailability).reduce(
          (acc, curr) => {
            let isRecommended = false
            acc.list.push({
              ...mapAvailableProvider(curr),
              isRecommended,
            })
            return acc
          },
          { recommendedCount: 0, list: [] },
        )

        dispatch(
          putProviders({
            providers: results.list,
          }),
        )
      } else if (data && data.providerId) {
        dispatch(
          putProvider({
            provider: mapAvailableProvider(data),
          }),
        )
      }
    }

    const fetchType =
      scheduledType === VisitScheduleType.scheduled ? FetchType.getAvailableProviders : FetchType.getAvailableProviders

    await makeRequest(fetchType, params, handleSuccess, handleRequestFailure(dispatch))

    dispatch(
      setFetching({
        fetchType: FetchType.getAvailableProviders,
        isFetching: false,
      }),
    )
    return { success, data: result }
  }

export const fetchProviderAvailability = (memberId, providerId, visitType) => async (dispatch) => {
  dispatch(
    setFetching({
      fetchType: FetchType.getProviderAvailability,
      isFetching: true,
    }),
  )

  let success = false
  let result = null

  const params = {
    providerId,
    params: {
      filter: {
        memberId,
        visitType,
      },
    },
  }

  const handleSuccess = async (data) => {
    success = true
    result = data
    dispatch(
      putAvailability({
        providerId,
        availability: data,
      }),
    )
  }

  await makeRequest(FetchType.getProviderAvailability, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(
    setFetching({
      fetchType: FetchType.getProviderAvailability,
      isFetching: false,
    }),
  )
  return { success, data: result }
}

export const fetchAllProvidersIfNeeded = () => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.maybeGetAllProviders, isFetching: true }))
  dispatch(
    determineRequestNeediness({
      cacheTtlInMinutes: 5,
      fetchNext: fetchAllProviders(),
      // lastFetchSelector: ProviderSelectors.getLastFetch
    }),
  )
  dispatch(setFetching({ fetchType: FetchType.maybeGetAllProviders, isFetching: false }))
}

export const fetchProvider = (providerId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.getProvider, isFetching: true }))
  dispatch(putProviderFetching({ providerId, isFetching: true }))
  const params = { providerId }
  const handleSuccess = (data) => {
    dispatch(putProvider({ provider: data }))
  }

  await makeRequest(FetchType.getProvider, params, handleSuccess, handleRequestFailure(dispatch))

  dispatch(putProviderFetching({ providerId, isFetching: false }))
  dispatch(setFetching({ fetchType: FetchType.getProvider, isFetching: false }))
}

export const fetchProviderIfNeeded = (providerId) => async (dispatch) => {
  dispatch(setFetching({ fetchType: FetchType.maybeGetProvider, isFetching: true }))
  dispatch(
    determineRequestNeediness({
      cacheTtlInMinutes: 5,
      fetchNext: fetchProvider(providerId),
      lastFetchSelector: ProviderSelectors.getProviderLastFetch(providerId),
      existingSelector: ProviderSelectors.getProvider(providerId),
    }),
  )

  dispatch(setFetching({ fetchType: FetchType.maybeGetProvider, isFetching: false }))
}

export const fetchAvailableQueueProviders = (location, visitType) => async (dispatch, getState) => {
  dispatch(setFetching({ fetchType: FetchType.getAvailableQueueProviders, isFetching: true }))
  let success = false
  let count = -1
  const fetchableType = visitType === VisitTypes.therapyNow ? VisitTypes.therapy : visitType
  const restrictions = getServiceRestrictions(fetchableType)(getState())
  const stateLicenseRequired = restrictions.some(
    (r) =>
      r.restriction === RestrictionTypes.stateLicenseRequired &&
      (!r.restrictionValue || r.restrictionValue === location),
  )
  const visitLocation = !stateLicenseRequired && fetchableType === VisitTypes.therapy ? '*' : location

  const params = {
    params: {
      filter: {
        'notifications.snooze': 'null',
        'licenses.state': visitLocation,
        'licenses.type': fetchableType,
      },
      page: 0,
      limit: 1,
    },
  }

  const handleSuccess = (data) => {
    success = true
    if (data?.meta) {
      count = data.meta.results
    }
  }
  await makeRequest(FetchType.getAvailableQueueProviders, params, handleSuccess, handleRequestFailure(dispatch))
  dispatch(setFetching({ fetchType: FetchType.getAvailableQueueProviders, isFetching: false }))
  return { success, count }
}
